<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID" prop="userNo" >
        {{form.userNo}}
      </a-form-model-item>
      <a-form-model-item label="用户昵称" prop="nickname" >
        {{form.nickname}}
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="invitationCode" >
        {{form.invitationCode}}
      </a-form-model-item>
      <a-form-model-item label="跳转房间号" prop="roomNo" >
        <a-input v-model="form.roomNo" placeholder="请输入跳转房间号" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGuild, addGuild, setJumpRoomNo ,getGroupList} from '@/api/biz/guildUser'

export default {
  name: 'SetGroupForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      groupList:[],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        guildId: null,

        userId: null,

        userIdentity: null,

        position: null,

        signDateStart: null,

        signDateEnd: null,

        firstSignDate: null,

        renewTimes: null,

        lastTimeSt: null,

        lastTimeEnd: null,

        createTime: null,
        groupId:null,roomNo:null,
        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        roomNo: [
          { required: true, message: '跳转房间号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.initGroupList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    initGroupList(){
      getGroupList({}).then(response => {
        for(let i=0;i<response.data.length;i++){
          let params = {
            value:response.data[i].id,
            label:response.data[i].groupName
          }
          this.groupList.push(params);
        }
      });
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        guildId: null,
        userId: null,
        userIdentity: null,
        position: null,
        signDateStart: null,
        signDateEnd: null,
        firstSignDate: null,
        renewTimes: null,
        lastTimeSt: null,
        lastTimeEnd: null,
        createTime: null, groupId:null,roomNo:null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      let that=this;
      // that.reset()
      that.form={}
      that.formType = 2
      that.form = JSON.parse(JSON.stringify(row));
      // getGuild({"id":row.id}).then(response => {
      //   that.form = response.data
         that.open = true
      // })
      that.formTitle = '跳转配置'

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            debugger
            console.log(this.form);
            setJumpRoomNo({id:this.form.userId,roomNo:this.form.roomNo}).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
